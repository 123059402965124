<template>
    <!-- MODAL RECUPERY PASSWORD -->
<div class="modal modal-default fade in" tabindex="-1" role="dialog" id="modal-recupery-password">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button class="modal-btn-close" @click="$root.closeModal('modal-recupery-password')">
				X
			</button>
			<div class="row">
				<div class="col-12">
					<div class="modal-header">
						<h2 class="modal-title">Recuperar password</h2>
					</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="modal-body-itens">
					<form ref="formRecover" @submit.prevent="onRecoverPass" class="modal-body-itens-form">
						<div class="row">
							<div class="col-lg-6">
								<div class="content-input">
									<label>E-mail</label>
									<input type="email" value="" name="email" placeholder="" required/>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<div class="modal-body-itens-form-btns">
									<button type="submit">Recuperar</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<!-------------------->
</template>
<script>
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
    methods:{
        ...mapActions('App', ['loading']),        
        async onRecoverPass(el){            
            let formData = new FormData(this.$refs.formRecover)

            this.loading(true);
            await axios({
                method: 'POST',
                url: '/api/v1/campaign/user/recover-pass',
                data: formData
            }).then(response => response.data)
            .then((data) => {                
                this.loading(false);
                this.$notify({                    
                    type: "success",
                    text: data.message,
                    duration: 10000
                });
                this.$root.closeModal("modal-recupery-password")
            })
            .catch((error) => {
                this.loading(false);
                this.$notify({                    
                    type: "warn",
                    text: error.response.data.message,
                    duration: 10000
                });
            })
        }
    }
}
</script>