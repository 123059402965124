<template>
    <!-- MODAL REGISTER -->
    <div class="modal modal-default fade in" tabindex="-1" role="dialog" id="modal-register">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <button class="modal-btn-close" @click="$root.closeModal('modal-register')">
                    X
                </button>
                <div class="row">
                    <div class="col-12">
                        <div class="modal-header">
                            <h2 class="modal-title">Novo registo</h2>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="modal-body-itens">
                        <form ref="formRegister" @submit.prevent="onRegister" class="modal-body-itens-form">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Nome</label>
                                        <input type="text" value="" name="name" placeholder="" required/>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Código de Colaborador</label>
                                        <input type="text" value="" name="code" placeholder=""/>
                                    </div>
                                </div> -->
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Departamento Delta</label>
                                        <input type="text" value="" name="department" placeholder=""/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Telemóvel</label>
                                        <input type="text" value="" name="phone" placeholder="" required/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>E-mail</label>
                                        <input type="email" value="" name="email" placeholder="" required/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Password</label>
                                        <input type="password" value="" name="password" placeholder="" required/>
                                        <!-- hidden input owner_id 1 == MAISHORECA -->
                                        <input type="hidden" value="1" name="owner_id" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="modal-body-itens-form-btns">
                                        <button type="submit">Criar registo</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-------------------->
</template>
<script>
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
    methods:{
        ...mapActions('App', ['loading']),        
        async onRegister(el){            
            let formData = new FormData(this.$refs.formRegister)

            this.loading(true);
            await axios({
                method: 'POST',
                url: '/api/v1/campaign/user/register',
                data: formData
            }).then(response => response.data)
            .then((data) => {                
                this.loading(false);
                this.$notify({                    
                    type: "success",
                    text: data.message,
                    duration: 10000
                });
                this.$root.closeModal("modal-register")
            })
            .catch((error) => {
                this.loading(false);
                this.$notify({                    
                    type: "warn",
                    text: error.response.data.message,
                    duration: 10000
                });
            })
        }
    }
}
</script>