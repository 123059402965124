<template>
    <main class="login">

        <section class="content-login">
            <div class="content-login-itens container">
                <div class="content-logo">
                   <router-link to="/">
                    <img class="img-fluid" src="@/assets/img/logos/logo-login.png" alt="logo mais horeca"
                        title="logo mais horeca" />
                    </router-link>
                </div>
                <form ref="formLogin" @submit.prevent="onLogin" class="content-login-itens-form">
                    <div class="content-input">
                        <label>E-mail</label>
                        <input type="email" name="email" value="" placeholder="" required />
                    </div>
                    <div class="content-input">
                        <label>Password</label>
                        <input type="password" name="password" value="" placeholder="" required />
                    </div>
                    <div class="content-btns">
                        <button type="submit" class="content-btns-login">
                            Entrar
                        </button>
                        <a class="content-btns-recupery-password" @click="$root.openModal('modal-recupery-password')">
                            Recuperar password
                        </a>
                    </div>
                </form>
                <div class="content-btn-new-register">
                    <button class="content-btn-new-register-link" @click="$root.openModal('modal-register')">
                        Novo Registo
                    </button>
                </div>
            </div>
        </section>

        <ModalRegister />
        <ModalRecoverPass />
    </main>
</template>
<script>
import ModalRegister from '@/components/ModalRegister';
import ModalRecoverPass from '@/components/ModalRecoverPass';
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
    components: {
        ModalRegister,
        ModalRecoverPass
    },
    methods: {
        ...mapActions('App', ['loading']),
        ...mapActions('User', ['setUser', 'setToken']),
        async onLogin(el){            
            let formData = new FormData(this.$refs.formLogin)

            this.loading(true);
            await axios({
                method: 'POST',
                url: '/api/v1/campaign/user/login',
                data: formData
            }).then(response => response.data)
            .then((data) => {
                this.loading(false);
                this.setUser(data.data);
                this.setToken(data.data.token);
                this.$router.push({
                    name: 'my-leads'
                })
            })
            .catch((error) => {
                this.loading(false);
                this.$notify({                    
                    type: "warn",
                    text: error.response.data.message,
                    duration: 10000
                })
            })
        }
    }
}
</script>